import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

const BackToBusinessPage = (props) => {
  const city_state = "San Diego, CA"
  const practice_name =
    "Oral Surgery & Dental Implant Specialists of San Diego "
  // If no video consult, set design to two. Else, set to three.
  const design = "two"
  const biz_tel = "(619) 287-5000"
  const biz_tel_stripped = biz_tel.replace(/\D/g, "")
  // If no video consult, set let youtube to no-youtube. Else, set to any string.
  const youtube = "no-youtube"
  const youtube_code = ""
  const youtubeImageId = ""
  const form_url =
    "https://secureform.seamlessdocs.com/f/sdosconlinevideoconsult"

  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          lang="en"
          title={`We Are Open For Care in ${city_state}`}
          description={`We are excited to announced we are open for care in ${city_state}. Please call us to schedule an appointment with our practice.`}
        />
        <div className="joshua-tree-content">
          <div className="b2b-banner">
            <h1>WE ARE OPEN</h1>
          </div>
          <div className="b2b-top-section">
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <p className="top-p">
                  The entire {practice_name} team is excited to resume both
                  emergency and elective treatments again and fulfill your oral
                  surgery needs. We are scheduling appointments now.{" "}
                </p>
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="treatment-grid">
              <div className="columns">
                <div className="column is-6"></div>
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/doors-icon-blue.svg" />
                  </div>
                  <h3>
                    Our Doors Are Open
                    <br />
                    For Our Community
                  </h3>
                  <p>
                    We are now scheduling appointments. If you need pediatric
                    dental care, our team is here for&nbsp;you.
                  </p>
                </div>
                <div className="column is-1"></div>
                {design === "three" && (
                  <>
                    <div className="teleconsult-box treatment-column column">
                      <a
                        className="teleconsult-link"
                        target="_blank"
                        href={form_url}
                      >
                        <div className="icon-wrapper">
                          <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/teleconsult-icon-blue.svg" />
                        </div>
                        <h3>
                          Offering Online Video
                          <br />
                          Appointments
                        </h3>
                        <p>
                          If you cannot visit the office, we are offering online
                          video consultations for you to be evaluated by
                          a&nbsp;specialist.
                        </p>

                        <div style={{ textAlign: "center" }}>
                          <a
                            target="_blank"
                            href={form_url}
                            className="b2b-button standard-button standalone has-text-centered"
                          >
                            Schedule Today
                          </a>
                        </div>
                      </a>
                    </div>
                    <div className="column is-1"></div>
                  </>
                )}
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/safety-icon-blue.svg" />
                  </div>
                  <h3>
                    Committed to
                    <br />
                    Keeping You Safe
                  </h3>
                  <p>
                    We are taking additional measures to ensure the health and
                    safety of our patients and staff while our office
                    remains&nbsp;open.
                  </p>
                </div>
                <div className="column is-6"></div>
              </div>
            </div>
          </div>

          <section
            className={"body-section " + youtube}
            style={{ paddingBottom: 0 }}
          >
            <div className="columns">
              <div className="column is-18 is-offset-3">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${youtube_code}`}
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={youtubeImageId}
                    width="auto"
                  ></ImageMeta>
                </TheaterVideo>
              </div>
            </div>
          </section>

          <section className="body-section">
            <div className="b2b-body wrapper columns">
              <div className="column is-5"></div>

              <div className="column">
                <h5>Our Commitment to Health & Safety</h5>
                <p className="large">
                  As always, our practice takes every precaution to keep
                  patients and staff safe and healthy. While we have always
                  adhered to and exceeded the standards for decontamination set
                  by{" "}
                  <a
                    target="_blank"
                    href="https://www.osha.gov/OshDoc/data_Hurricane_Facts/general_decontamination_fact.html"
                  >
                    OSHA
                  </a>
                  , we are going one step further and incorporating the latest{" "}
                  <a
                    target="_blank"
                    href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
                  >
                    CDC
                  </a>{" "}
                  guidelines.
                </p>
                <h5>The Measures We Are Taking To Protect Our Patients</h5>
                <p className="large">
                  We ask you to keep the following in mind prior to and during
                  your appointment:
                </p>

                <ul>
                  <li>
                    We will schedule patients to limit the number of individuals
                    in our lobby and office at any given time. We will also be
                    staggering appointments to allow for proper sanitization
                    after each patient visit.
                  </li>
                  <li>
                    All patients will be screened leading up to their
                    appointment. We will complete a phone screening with
                    recommended COVID questionnaires at the time of scheduling
                    and appointment confirmation. Patients will also fill out a
                    form upon check-in to verify these questionnaires. All
                    patients and everyone in their party must have their
                    temperature taken as well, will be provided with hand
                    sanitizer, and will be required to wear masks while in the
                    practice.
                  </li>
                  <li>
                    The waiting room is wiped down on a regular schedule after
                    every patient check in, especially high traffic areas. The
                    lobby, clipboards, pens and restroom including all door
                    knobs or high traffic areas are wiped down between each
                    patient on a regular schedule throughout the day.
                  </li>
                  <li>
                    Hand wash stations will be located in the restrooms,
                    treatment rooms, sterilization area and lunch room with
                    antibacterial soap.
                  </li>
                  <li>
                    While it is not possible to completely social distance
                    during surgery, our team will all be wearing our proper PPE
                    when in surgery. We ask patients to sit away from each other
                    in the lobby and rides to wait in the car if possible. We
                    have removed some seating to promote social distancing and
                    our front office staff will verbally directs patients where
                    to sit to encourage safe social distancing.
                  </li>
                  <li>
                    Patients will be also required to use a mouth rinse prior to
                    surgery. This ADA-accepted, chlorine dioxide ultra sensitive
                    mouth rinse rapidly reduces the viral and bacterial load in
                    the mouth and has also been shown to promote healing within
                    the mouth.
                  </li>
                  <li>
                    After surgery, patients will be re-masked and escorted to
                    their car while another employee decontaminates the post
                    operative room the patient was previously in per COVID
                    protocol.
                  </li>
                  <li>
                    Our team will will continue to follow a strict
                    decontamination protocol with hospital grade disinfectants,
                    including but not limited to Cavicide, sanicloth Prime,
                    sanicloth AE3, Germicidal UV-C sanitzation lights, and Multi
                    phase HEPA filtration systems.
                  </li>
                </ul>

                <h5>How We Are Protecting the Safety of Our Staff & Doctors</h5>
                <p className="large">
                  We are taking additional steps toward ensuring the safety of
                  our staff.
                </p>
                <ul>
                  <li>
                    All team members will follow strict personal protective
                    equipment protocols. All surgical staff will be required to
                    wear N95 or KN95 masks, goggles and/or face shields, hair
                    nets, and gloves and our front office staff will wear masks
                    at all times.{" "}
                  </li>
                  <li>
                    Each staff members will be screened before their shift
                    begins, using the same risk assessment questions we ask of
                    our patients and we will document their temperatures as
                    well.
                  </li>
                </ul>

                <p className="large">
                  <strong>IMPORTANT MESSAGE TO PATIENTS:</strong> If you or
                  someone in your household have a fever, cough, or flu-like
                  symptoms, please stay at home and don’t come to the practice.
                  Your doctor may request an online video consultation if you
                  are displaying symptoms.
                </p>

                <p className="large">
                  At {practice_name} we appreciate the ongoing support from our
                  patients, referring providers, families, and friends. Thank
                  you for your patience and cooperation while these measures are
                  put into effect.
                </p>

                <p className="large">
                  For questions or clarification about these measures, please
                  contact us at{" "}
                  <a href={`tel:+1${biz_tel_stripped}`}>{biz_tel}</a>.
                </p>
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="b2b-cta wrapper columns">
              <div className="column is-5"></div>
              <div className="column">
                <h5>Schedule Your Appointment Today</h5>
                <p>
                  Call our office to schedule an appointment for any procedure.
                </p>

                <a
                  href={`tel:+1${biz_tel_stripped}`}
                  className="standard-button b2b-button"
                >
                  Call Us
                </a>
              </div>

              <div className="column is-5"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default BackToBusinessPage
